
@font-face {
  font-family: 'OjahDisplay';
  src: url('../fonts/OjahDisplay-SemiBold.eot');
  src: url('../fonts/OjahDisplay-SemiBold.ttf') format('truetype'),
       url('../fonts/OjahDisplay-SemiBold.eot?#iefix') format('embedded-opentype'),
       url('../fonts/OjahDisplay-SemiBold.svg#OjahDisplay-SemiBold') format('svg'),
       url('../fonts/OjahDisplay-SemiBold.woff') format('woff'),
       url('../fonts/OjahDisplay-SemiBold.woff2') format('woff2');
       font-weight: 600;
       font-display: swap;
       font-style: normal;
}

@font-face {
  font-family: 'OjahDisplay';
  src: url('../fonts/OjahDisplay-Bold.eot');
  src: url('../fonts/OjahDisplay-Bold.ttf') format('truetype'),
       url('../fonts/OjahDisplay-Bold.eot?#iefix') format('embedded-opentype'),
       url('../fonts/OjahDisplay-Bold.svg#OjahDisplay-Bold') format('svg'),
       url('../fonts/OjahDisplay-Bold.woff') format('woff'),
       url('../fonts/OjahDisplay-Bold.woff2') format('woff2');
       font-weight: 700;
       font-display: swap;
       font-style: normal;
}

@font-face {
  font-family: 'OjahDisplay';
  src: url('../fonts/OjahDisplay-Black.eot');
  src: url('../fonts/OjahDisplay-Black.ttf') format('truetype'),
       url('../fonts/OjahDisplay-Black.eot?#iefix') format('embedded-opentype'),
       url('../fonts/OjahDisplay-Black.svg#OjahDisplay-Black') format('svg'),
       url('../fonts/OjahDisplay-Black.woff') format('woff'),
       url('../fonts/OjahDisplay-Black.woff2') format('woff2');
       font-weight: 900;
       font-display: swap;
       font-style: normal;
}

.PopoverContent[data-side='top'] {
  animation-name: slideUp;
}
.PopoverContent[data-side='bottom'] {
  animation-name: slideDown;
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}