@import url("../fonts/OjahDisplay/css/index.css");
@import url("../fonts/Geist/css/index.css");

@tailwind base;
@tailwind components;
@tailwind utilities;

[type="text"]:focus,
[type="email"]:focus,
[type="url"]:focus,
[type="password"]:focus,
[type="number"]:focus,
[type="date"]:focus,
[type="datetime-local"]:focus,
[type="month"]:focus,
[type="search"]:focus,
[type="tel"]:focus,
[multiple]:focus,
textarea:focus,
select:focus {
  @apply focus:outline-0 focus:ring-0;
}

.PhoneInputInput {
  border-width: 0 !important;
  background-color: transparent !important;
}

@layer base {
  :root {
    --background: 0 0% 100%;
    /* --foreground: 0 0% 3.9%; */

    --primary: 243 90% 66%;
    --white: 0 0% 100%;

    --border: 0 0% 96%;
    --input: 0 0% 89.8%;
    --ring: 0 0% 3.9%;
    --warn: 0 100% 65%;

    --green: 153 100% 38%;
    --black: 60 1% 20%;

    --subtext-gray: 240 1% 61%;
    --subtext-light-gray: 0 0% 60%;
    --icon-container-gray: 220 5% 89%;
    --dashboard-gray: 0 0% 98%;
    --form-gray: 0 0% 71%;

    --yellow: 43 100% 52%;
    --yellow-dark: 36 100% 47%;

    --popover: 210 4% 9%;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px #fff inset;
    transition: background-color 5000s ease-in-out 0s;
  }

  input[class="form-input"]:-webkit-autofill,
  input[class="form-input"]:-webkit-autofill:hover,
  input[class="form-input"]:-webkit-autofill:focus,
  textarea[class="form-input"]:-webkit-autofill,
  textarea[class="form-input"]:-webkit-autofill:hover,
  textarea[class="form-input"]:-webkit-autofill:focus,
  select[class="form-input"]:-webkit-autofill,
  select[class="form-input"]:-webkit-autofill:hover,
  select[class="form-input"]:-webkit-autofill:focus {
    @apply form-input;
  }

  input[class="form-input-icon"]:-webkit-autofill,
  input[class="form-input-icon"]:-webkit-autofill:hover,
  input[class="form-input-icon"]:-webkit-autofill:focus,
  textarea[class="form-input-icon"]:-webkit-autofill,
  textarea[class="form-input-icon"]:-webkit-autofill:hover,
  textarea[class="form-input-icon"]:-webkit-autofill:focus,
  select[class="form-input-icon"]:-webkit-autofill,
  select[class="form-input-icon"]:-webkit-autofill:hover,
  select[class="form-input-icon"]:-webkit-autofill:focus {
    @apply form-input-icon;
  }
}

@layer utilities {
  body {
    @apply text-sm text-shiga-black;
  }

  .custom-scrollbar::-webkit-scrollbar {
    width: 5px;
    height: 3px;
    border-radius: 2px;
  }

  .custom-scrollbar::-webkit-scrollbar-track {
    background: rgb(236, 231, 231);
  }

  .custom-scrollbar::-webkit-scrollbar-thumb {
    background: #7c7c98;
    border-radius: 50px;
  }

  .custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background: #7878a3;
  }
  .form-group {
    @apply mt-7 mb-2 space-y-1;
  }

  .form-group label {
    @apply block text-sm text-shiga-gray;
  }

  .form-input-required {
    @apply ml-1 text-red-500;
  }

  .form-input,
  .structure-form-input {
    @apply w-full h-12 py-1 text-black text-base bg-[#FAFAFB] border border-transparent rounded-2xl !important;
    @apply focus:outline-none focus:ring-2 focus:border-transparent focus:ring-shiga-black !important;
    @apply !pl-4 !pr-4 !placeholder-shiga-gray;
  }

  .form-input.disabled,
  .form-input[disabled] {
    /* @apply bg-gray-200 opacity-100; */
  }

  .form-input.disabled,
  .form-input[disabled],
  .form-input-textarea[disabled] {
    /* @apply text-[#777E90] bg-[#EEEFF2] border-[#EEEFF2] opacity-100 !important; */
  }

  .structure-form-input[disabled] {
    @apply text-[#777E90] bg-[#EEEFF2] !important;
  }

  .form-input-sm {
    @apply form-input !important;
    @apply h-7 text-sm !important;
  }

  .form-input-md {
    @apply form-input;
    @apply !h-10;
  }

  .form-input-lg {
    @apply form-input;
    @apply !h-12;
  }

  .form-group-icon {
    @apply w-full text-sm bg-white rounded ring-2 ring-transparent;
    @apply flex overflow-hidden;
  }

  .form-group-icon:focus-within {
    @apply border-transparent outline-none;
  }

  .form-group-icon>label {
    @apply w-10 h-full flex flex-shrink-0;
  }

  .form-group-icon>label>.form-group-icon-attach {
    @apply m-auto;
  }

  .form-input-icon {
    @apply w-full h-9 px-0 py-1 text-sm border-none outline-none focus:border-transparent focus:outline-none focus:ring-0;
  }

  .svg-fill-white,
  .svg-fill-white * {
    color: #fff !important;
    fill: #fff !important;
  }

  .svg-fill-black,
  .svg-fill-black * {
    color: #000 !important;
    fill: #000 !important;
  }

  .svg-fill-orange,
  .svg-fill-orange * {
    color: #ff895a !important;
    fill: #ff895a !important;
  }

  .svg-fill-ccc,
  .svg-fill-ccc * {
    color: #ccc !important;
    fill: #ccc !important;
  }

  .svg-fill-red,
  .svg-fill-red * {
    color: #f00 !important;
    fill: #f00 !important;
  }

  .svg-fill-inherit,
  .svg-fill-inherit * {
    color: inherit !important;
    fill: inherit !important;
  }

  .svg-fill-primary,
  .svg-fill-primary *,
  .sidebar-links.active .svg-fill-white,
  .sidebar-links.active .svg-fill-white * {
    color: #625bf6 !important;
    fill: #625bf6 !important;
  }

  .svg-stroke-white,
  .svg-outline-white,
  .svg-stroke-white *,
  .svg-outline-white * {
    color: transparent !important;
    fill: transparent !important;
    stroke: #fff !important;
  }

  .svg-stroke-black,
  .svg-outline-black,
  .svg-stroke-black *,
  .svg-outline-black * {
    color: transparent !important;
    fill: transparent !important;
    stroke: #000 !important;
  }

  .svg-stroke-gray,
  .svg-outline-gray,
  .svg-stroke-gray *,
  .svg-outline-gray * {
    color: transparent !important;
    fill: transparent !important;
    stroke: #99999c !important;
  }

  .svg-stroke-dark,
  .svg-outline-dark,
  .svg-stroke-dark *,
  .svg-outline-dark * {
    color: transparent !important;
    fill: transparent !important;
    stroke: #28303f !important;
  }

  .svg-stroke-primary,
  .svg-outline-primary,
  .svg-stroke-primary *,
  .svg-outline-primary *,
  .sidebar-links.active .svg-stroke-white,
  .sidebar-links.active .svg-outline-white,
  .sidebar-links.active .svg-stroke-white *,
  .sidebar-links.active .svg-outline-white * {
    color: transparent !important;
    fill: transparent !important;
    stroke: #625bf6 !important;
  }

  #nav-icon {
    @apply w-7 h-9 m-auto relative cursor-pointer;
    transform: rotate(0deg);
    transition: 0.5s ease-in-out;
  }

  #nav-icon span {
    @apply w-full h-1;
    @apply block fixed left-0 opacity-100 rounded-md bg-black;
    transform: rotate(0deg);
    transition: 0.25s ease-in-out;
  }

  #nav-icon.nav-icon-white span {
    @apply bg-white !important;
  }

  #nav-icon span:first-child {
    @apply top-2;
  }

  #nav-icon span:nth-child(2),
  #nav-icon span:nth-child(3) {
    @apply top-4;
  }

  #nav-icon span:nth-child(4) {
    @apply top-6;
  }

  #nav-icon.open span {
    --tw-bg-opacity: 1;
    background-color: rgba(66, 111, 244, var(--tw-bg-opacity));
  }

  #nav-icon.open span:first-child {
    @apply w-0 top-[18px] left-1/2;
  }

  #nav-icon.open span:nth-child(2) {
    transform: rotate(45deg);
  }

  #nav-icon.open span:nth-child(3) {
    transform: rotate(-45deg);
  }

  #nav-icon.open span:nth-child(4) {
    @apply w-0 top-[18px] left-1/2;
  }

  .text-fade {
    @apply text-gray-400;
  }

  .btn-default {
    @apply w-max font-medium !rounded-2xl;
    @apply flex items-center justify-center space-x-2 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-opacity-75 cursor-pointer;
  }

  .btn {
    @apply btn-default;
    @apply btn-md;
  }

  .btn,
  .btn * {
    @apply whitespace-nowrap;
  }

  .btn.disabled,
  .btn[disabled] {
    @apply cursor-default;
    opacity: 0.7;
    filter: opacity(70);
  }

  @media (min-width: 768px) {
    .btn-md-lg {
      @apply btn-lg;
    }
  }

  @media (max-width: 767px) {
    .btn-md-lg {
      @apply btn-md;
    }
  }

  .btn-sm {
    @apply h-7 px-4 text-xs !rounded-lg;
  }

  .btn-md {
    @apply h-10 px-5 text-sm !rounded-xl;
  }

  .btn-lg {
    @apply h-12 px-6 text-base !rounded-2xl;
  }

  .btn-block {
    @apply w-full;
  }

  .btn-transparent-black {
    @apply border border-gray-400;
  }

  .form-checkbox,
  .form-input-checkbox {
    @apply rounded cursor-pointer !important;
  }

  .form-radio,
  .form-input-radio {
    @apply rounded-full cursor-pointer !important;
  }

  .form-input-message {
    @apply capitalize-first-letter text-sm text-shiga-warn font-medium;
  }

  .form-input-textarea {
    @apply w-full px-4 py-2 text-black text-sm border border-gray-300 rounded focus:outline-none focus:ring-2 focus:border-transparent !important;
  }

  .animate {
    @apply transform transition duration-500 ease-in-out;
  }

  .react-slideshow-container+ul.indicators .each-slideshow-indicator:before {
    background: #fff;
  }

  .sidebar-links-divider {
    @apply mt-4 mb-2 font-medium uppercase px-6 text-[#DCDEE3] text-xs;
  }

  .sidebar-links {
    @apply h-11 px-2 mx-2.5 md:px-3 md:mx-4 flex text-[#99999C] items-center relative cursor-pointer rounded-md;
    @apply hover:bg-[#f0f0f0];
  }

  .sidebar-collapsed .sidebar-links {
    @apply !px-0 !justify-center;
  }

  .sidebar-links.active {
    @apply text-[#5540EB];
  }

  .sidebar-links.active>.sidebar-links-icon {
    @apply svg-fill-primary;
  }

  .sidebar-links>div {
    @apply w-full flex justify-between;
  }

  .sidebar-collapsed .sidebar-links>div {
    @apply hidden;
  }

  .sidebar-links-icon {
    @apply !w-7 !h-7 text-[#E5FDFF] flex-shrink-0 text-center mr-3 object-contain;
  }

  .sidebar-collapsed .sidebar-links-icon {
    @apply !mr-0;
  }

  .sidebar-collapsed .sidebar-links-parent>div:nth-child(2) {
    @apply !hidden;
  }

  .sidebar-angle-down {
    @apply w-5 h-5 text-[#E5FDFF] text-center ml-3 object-contain;
  }

  /*
	.sidebar-links-highlight {
		@apply w-0.5 h-6 mr-6 bg-white invisible;
	}

	.sidebar-links.active .sidebar-links-highlight {
		@apply -ml-3;
	}

	.sidebar-links-border-curve {
		@apply w-4 h-4 absolute right-0;
		@apply bg-white;
		@apply hidden;
	}

	.sidebar-links-border-curve > div {
		@apply w-4 h-4 bg-[#193F52];
	}

	.sidebar-links.active .sidebar-links-border-curve-1 {
		@apply sidebar-links-border-curve;
		@apply -top-4 block;
	}

	.sidebar-links.active .sidebar-links-border-curve-1 > div {
		@apply rounded-br-md;
	}

	.sidebar-links.active .sidebar-links-border-curve-2 {
		@apply sidebar-links-border-curve;
		@apply -bottom-4 block;
	}

	.sidebar-links.active .sidebar-links-border-curve-2 > div {
		@apply rounded-tr-md;
	}

	.header-navigation-links .sidebar-links-border-curve-1,
	.header-navigation-links .sidebar-links-border-curve-2 {
		@apply hidden !important;
	}

	.sidebar-links:not(.active):hover .sidebar-links-border-curve-1 {
		@apply w-4 h-4 absolute z-10 bottom-0 right-0;
	}
	*/

  .object-cover,
  .object-contain {
    @apply w-full h-full;
  }

  .container {
    @apply mx-auto px-4 xl:px-10;
  }

  .dropdown {
    /* @apply min-w-[100px] w-max p-1 text-white text-sm bg-[#222222] absolute z-[9] outline-0 rounded-2xl overflow-hidden; */
    @apply min-w-[100px] w-max p-1 text-white text-sm bg-[#222222] absolute z-[9] outline-0 rounded-2xl;
  }

  .dropdown-left {
    @apply left-0;
  }

  .dropdown-right {
    @apply right-0;
  }

  .dropdown-item {
    /* @apply px-3.5 py-3.5 flex items-center cursor-pointer; */
    @apply px-4 py-2.5 flex items-center rounded-xl cursor-pointer;
    @apply hover:bg-[#444444];
  }

  .dropdown-item-icon>*:first-child {
    @apply w-5 h-5 mr-3 overflow-hidden;
  }

  .box-border-only {
    @apply bg-white rounded-md border;
    /* border-color: #9CA3AF; */
  }

  .box-default-overflow {
    @apply p-4 sm:p-6 box-border-only;
  }

  .box {
    @apply box-default-overflow overflow-hidden;
  }

  .box-no-padding {
    @apply p-0 xl:p-0;
  }

  .label {
    @apply px-3 py-1 rounded-sm whitespace-nowrap inline font-bold;
    font-size: 12px;
    text-transform: lowercase;
  }

  .label:first-letter,
  .label:first-line {
    text-transform: uppercase !important;
  }

  .label.label-default-case:first-letter,
  .label.label-default-case:first-line {
    text-transform: none !important;
  }

  .label-blue {
    @apply bg-blue-200;
  }

  .label-green {
    @apply text-[#15692A] bg-[#D4F7DC];
  }

  .label-red {
    @apply text-[#FC5057] bg-[#FFD4D2];
  }

  .label-gray {
    @apply bg-gray-200;
  }

  .label-yellow {
    @apply text-[#804C00] bg-[#FFEBCC];
  }

  .table-container {
    @apply w-full;
    overflow-x: auto !important;
    overflow-y: auto !important;
  }

  .table-container>.table th,
  .table-container>.table td {
    @apply w-min;
    @apply whitespace-nowrap print:whitespace-normal;
  }

  .table-container>.table.no-whitespace th,
  .table-container>.table.no-whitespace td {
    @apply !whitespace-normal;
  }

  /* .table-container {
        @apply w-full overflow-x-auto overflow-y-hidden;
    }

    .table-container > .table th,
    .table-container > .table td {
        @apply w-min;
    } */

  .table {
    @apply w-full min-w-min overflow-hidden;
    border-collapse: separate;
    /* border-spacing: 0 12px; */
    border-spacing: 0 0;
  }

  .table thead th {
    @apply px-4 py-2 text-shiga-gray-300 text-sm text-left font-medium font-inter uppercase bg-shiga-gray-40;
  }

  .table thead tr>th:first-child {
    @apply rounded-tl-lg rounded-bl-lg;
  }

  .table thead tr>th:last-child {
    @apply rounded-tr-lg rounded-br-lg;
  }

  .table tbody {
    /* @apply bg-white; */
  }

  .table tbody tr {
    /* @apply border-t border-b border-black !important; */
  }

  .table tbody tr:not(.table-no-data):hover {
    /* @apply bg-gray-50; */
  }

  .table tbody tr td {
    @apply px-4 py-5 text-left;
    @apply border-b border-gray-100;
  }

  .table tbody tr:last-child>td {
    @apply !border-none;
  }

  .table.table-align-top td {
    @apply align-top;
  }

  .table td .table-dropdown-align-top {
    @apply -mt-2;
  }

  .table-info-full-page {
    @apply box;
    @apply py-24 mt-6;
  }

  .table-info {
    @apply px-2 py-20 text-center text-lg;
  }

  .table-info>svg {
    /* @apply text-4xl mb-2; */
  }


  .table-info > *:first-child {
    @apply text-5xl mb-2;
  }

  .page-info-italic {
    @apply py-2 text-center italic text-gray-500;
  }

  .pagination {
    @apply flex rounded space-x-0.5 overflow-hidden;
  }

  .pagination-items {
    @apply h-8 px-4 text-xs flex space-x-2 rounded-md cursor-pointer hover:bg-gray-200;
    @apply mr-1 !important;
  }

  .pagination-items>div {
    @apply my-auto;
  }

  .pagination-items-icon {
    @apply w-4 h-3 m-auto;
  }

  .pagination-items.disabled {
    @apply bg-gray-100 cursor-default hover:bg-gray-100;
  }

  .pagination-items-icon.disabled,
  .pagination-items-icon.disabled>div {
    @apply text-gray-300 group-hover:text-gray-300;
  }

  .pagination-items-active {
    @apply text-white bg-blue-500 hover:bg-blue-500;
  }

  .tab-pill {
    @apply px-5 sm:px-10 py-1 m-auto text-sm rounded-lg hover:text-white hover:bg-blue-500;
  }

  .ellipsis {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .ellipsis-2-lines,
  .ellipsis-two-lines,
  .ellipsis-line-2,
  .ellipsis-line-two,
  .ellipsis-lines-2,
  .ellipsis-lines-two {
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .tab {
    @apply px-4 py-2 text-shiga-gray border-b-2 border-transparent hover:text-shiga-black cursor-pointer;
  }

  .tab.active {
    @apply text-white bg-shiga-black rounded-full;
  }

  .tab-design-border {
    @apply flex border border-gray-300 rounded-md overflow-hidden;
  }

  .tab-design-border>div {
    @apply px-3 py-1 text-sm border-r border-gray-300 font-bold cursor-pointer;
  }

  .tab-design-border>div:last-child {
    @apply border-r-0;
  }

  .tab-design-border>div:hover {
    @apply bg-gray-200;
  }

  .tab-design-border>div.active {
    @apply text-white bg-blue-500;
  }

  .tab-design-button {
    @apply btn btn-lg btn-block;
    @apply ring-0 !important;
  }

  .note {
    @apply text-sm;
    padding: 5px 10px;
    text-align: justify;
    background: #ffea77 !important;
  }

  .floated-content:after {
    display: block;
    content: "";
    clear: both;
  }

  #headlessui-menu-items-2 {
    outline: none !important;
  }

  .bg-gray {
    background: #f9fafb;
  }

  .bg-fade-blue,
  .bg-blue-fade {
    background: #d9e3ff;
  }

  .react-confirm-alert-overlay {
    /* background: rgba(255, 255, 255, 0.77); */
    background: rgba(0, 0, 0, 0.77);
  }

  .capitalize-first-letter:first-letter {
    @apply uppercase;
  }

  .bg-blur {
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);
    /* background-color: rgba(255, 255, 255, 0.5); */
  }

  .form-input-floating {
    @apply form-input;
    @apply block px-2.5 pb-2.5 pt-4 appearance-none;
    @apply cursor-text;
  }

  .form-label-floating {
    @apply absolute text-sm duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 left-1;
    @apply peer-focus:px-2 peer-focus:text-vetiva-brown peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4;
    @apply w-full cursor-text bg-red-300 -mt-10;
  }

  .transfer-container {
    @apply w-full px-8 lg:px-10 pt-6 lg:pt-10;
  }

  .transfer-wrapper {
    @apply w-full md:w-3/5 lg:w-2/5 mx-auto max-w-[400px];
  }

  .transfer-page-title {
    @apply text-3xl my-12 font-ojah font-semibold text-shiga-dark-100;
  }

  .dashboard-page-container {
    @apply p-6 pt-4 lg:p-14 lg:pt-10 pb-10 lg:pb-20;
  }

  .table-hovers {
    @apply cursor-pointer;
  }

  .flexed {
    @apply flex items-center justify-center;
  }

  .bred {
    @apply border border-red-500;
  }

  .bgrn {
    @apply border border-blue-700;
  }

  .flex-btw {
    @apply flex items-center justify-between;
  }

  .transaction-detail-inline-wrapper {
    @apply w-full flex-btw border-b border-shiga-gray-50 pb-2.5 mb-3;
  }

  .transaction-detail-inline-wrapper-last {
    @apply w-full flex-btw border-none mb-0 pb-0;
  }

  .transaction-detail-inline-key {
    @apply text-sm text-shiga-gray-250 font-inter;
  }

  .transaction-detail-inline-value {
    @apply font-medium text-sm;
  }

    .transaction-receipt-section {
    @apply w-full border border-shiga-gray-50 mb-9 rounded-xl;
  }

    .transaction-receipt-header {
    @apply bg-shiga-gray-50 p-5 mb-3 rounded-t-xl;
  }

  .transaction-receipt-title {
    @apply text-shiga-dark-200 font-ojah font-semibold text-[13px];
  }

  .transaction-receipt-wrapper {
    @apply w-full flex-btw border-b border-shiga-gray-50 pb-2.5 px-5 mb-3;
  }

  .transaction-receipt-wrapper-last {
    @apply w-full flex-btw px-5 mb-3;
  }


  .transaction-receipt-inline-key {
    @apply text-sm text-shiga-dark-200 font-inter;
  }

  .transaction-receipt-inline-value {
    @apply font-semibold font-inter text-xs;
  }

  .table-filter__date {
    @apply text-white rounded-lg w-full !bg-shiga-dark-300 border-none !text-sm;
  }

  .settlement-detail-key {
    @apply lg:text-[14px] text-shiga-gray-300;
  }

  .settlement-detail-value {
    @apply font-medium text-shiga-dark-100 lg:text-[16px];
  }

  .flex-between {
    @apply flex justify-between;
  }

  .inline-transfer-wrapper {
    @apply w-full lg:w-3/5;
  }

  .file_upload_zone {
    @apply cursor-pointer;
  }
}


.form-label {
	margin-bottom: 5px !important;
	color: #2F284F !important;
	font-weight: 400 !important;
	font-size: 14px;
	text-align: left;
	line-height: 20px;
}

.form-field__select,
.form-field__input {
	background-color: #FAFAFB !important;
}

.form-field__select {
	border: 1px solid #5D506D;
	padding: 10px !important;
	border-radius: 12px !important;
	width: 100%;
	transition: all 150ms ease-in-out;
	outline-width: 0;
}

.transferHeading {
  color: var(--Text, #343433);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.28px;
}

.shimmer {
  position: relative;
  overflow: hidden;
  background: linear-gradient(90deg, #e0e0e0 25%, #f5f5f5 50%, #e0e0e0 75%);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
}

@keyframes progress {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(100%);
  }
}

.animate-progress {
  animation: progress 2s linear infinite;
}

  .snippet-container::-webkit-scrollbar {
		width: 5px;
    height: 7px;
    border-radius: 5px;
	}

	.snippet-container::-webkit-scrollbar-track {
		background-color: transparent;
	}

	.snippet-container::-webkit-scrollbar-thumb {
		background-color: #f0f0f0;
	}
