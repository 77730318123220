
@font-face {
    font-family: 'Geist';
    src: url('../fonts/Geist-SemiBold.ttf') format('truetype'),
         url('../fonts/Geist-SemiBold.otf') format('otf'),
         url('../fonts/Geist-SemiBold.woff') format('woff'),
         url('../fonts/Geist-SemiBold.woff2') format('woff2');
         font-weight: 600;
         font-display: swap;
         font-style: normal;
  }
  
  @font-face {
    font-family: 'Geist';
    src: url('../fonts/Geist-Bold.ttf') format('truetype'),
        url('../fonts/Geist-Bold.otf') format('otf'),
         url('../fonts/Geist-Bold.woff') format('woff'),
         url('../fonts/Geist-Bold.woff2') format('woff2');
         font-weight: 700;
         font-display: swap;
         font-style: normal;
  }
  
  @font-face {
    font-family: 'Geist';
    src: url('../fonts/Geist-Black.ttf') format('truetype'),
        url('../fonts/Geist-Black.otf') format('otf'),
         url('../fonts/Geist-Black.woff') format('woff'),
         url('../fonts/Geist-Black.woff2') format('woff2');
         font-weight: 900;
         font-display: swap;
         font-style: normal;
  }