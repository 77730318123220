@tailwind base;
@tailwind components;
@tailwind utilities;

.custom-scrollbar::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

@layer base {
  @import url("../fonts/OjahDisplay/css/index.css");
  @import url("../fonts/Geist/css/index.css");
  @import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

  body {
    color: #343433;
    font-size: 14px;
    font-family: "Inter", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
    font-variation-settings: "slnt" 0;
  }
}

@layer utilities {
  .font-inter {
    font-family: "Inter", sans-serif;
  }

  .font-ojah,
  .font-ojah-display,
  .font-ojahDisplay {
    font-family: "OjahDisplay", sans-serif;
    /* letter-spacing: -1px; */
    letter-spacing: -0.5px;
  }

  .font-geist {
    font-family: "Geist", sans-serif;
    letter-spacing: -0.5px;
  }

  .auth-footer-links {
    @apply inline border-b border-[#373737] border-opacity-30;
    @apply hover:text-shiga-black hover:border-shiga-black;
  }

  .hover-border {
    @apply relative  border-b-2 border-transparent;
  }

  .form-input,
  .form-input-textarea {
    @apply focus:ring-shiga-black;
  }

  .form-checkbox,
  .form-input-checkbox {
    @apply text-shiga-black focus:ring-[#625BF6];
  }

  input[type="checkbox"]:checked {
    @apply bg-shiga-purple border-shiga-black;
  }

  .form-radio,
  .form-input-radio {
    @apply text-[#625BF6] focus:ring-[#625BF6];
  }

  .form-group-icon:focus-within {
    @apply ring-shiga-black;
  }

  .btn-shiga-black {
    @apply text-white bg-shiga-black border-shiga-black focus:ring-shiga-black;
    /* @apply text-white border-green-700 focus:ring-green-700; */
    /* background-color: #1544CE; */
  }

  .btn-shiga-black.disabled,
  .btn-shiga-black[disabled] {
    opacity: 1;
    filter: opacity(100);
    @apply text-white bg-[rgba(0,0,0,0.3)];
  }

  .btn-black,
  .btn-primary {
    @apply btn-shiga-black;
  }

  .btn-white {
    @apply bg-white text-shiga-black border border-gray-200;
    @apply focus:ring-shiga-black;
  }

  .btn-red,
  .btn-shiga-warn {
    @apply text-white bg-shiga-warn focus:ring-shiga-warn;
  }

  .btn-orange {
    @apply text-white bg-shiga-orange focus:ring-shiga-orange;
  }

  .btn-transparent-primary {
    @apply text-shiga-black border border-shiga-black;
    @apply focus:ring-shiga-black;
  }

  .btn-danger {
    @apply text-white border border-ep-danger-100 bg-ep-danger-100;
  }

  .btn-transparent-black {
    @apply border border-shiga-black;
    @apply focus:ring-shiga-black;
  }

  .btn-transparent-white {
    @apply border border-white;
    @apply focus:ring-white;
  }

  .page-title {
    @apply text-3xl md:text-4xl font-ojah font-bold;
  }

  .custom-tooltip-ui {
    /* @apply  text-red-300 font-normal !important; */
    @apply text-sm z-[9999] opacity-100 shadow-md rounded-2xl !important;
  }

  .Toastify__toast-container {
    color: inherit;
  }
  .Toastify__toast {
    min-height: 55px;
    font-size: 13px !important;
    padding: 10px 10px;
    background: #ffffff !important;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
    border-left: 10px solid transparent;
  }
  .Toastify__toast--default {
    background: transparent;
    border-color: #888;
  }
  .Toastify__toast--default .Toastify__progress-bar {
    /* height: 100%; */
    /* background: #0c0 !important; */
    opacity: 0.5 !important;
    filter: opacity(50%) !important;
  }
  .Toastify__toast--success {
    background: transparent;
    border-color: #0c0;
  }
  .Toastify__toast--success .Toastify__progress-bar {
    /* height: 100%; */
    background: #0c0 !important;
  }
  .Toastify__toast--error {
    background: transparent;
    /* border-color: #e74c3c; */
    border-color: #f00;
  }
  .Toastify__toast--error .Toastify__progress-bar {
    /* height: 100%; */
    background: #f00 !important;
  }
  .Toastify__toast-body {
    width: 100px;
    color: #000;
    font-weight: bold;
  }
  .Toastify__close-button {
    width: 30px !important;
    height: 30px !important;
    margin: auto;
    /* background: #000 !important; */
    border: 1px solid rgba(0, 0, 0, 1);
    border-radius: 5px;
  }
  .Toastify__close-button > svg {
    color: rgba(0, 0, 0, 1);
    margin: auto;
    /* display: none; */
  }
  .Toastify__close-button > svg::after {
    /* content: "OK" */
  }
  .Toastify__close-button--default {
    opacity: 1;
    filter: opacity(100%);
  }
  .Toastify__progress-bar {
    /* background: rgba(0, 0, 0, 0.1); */
    /* transform-origin: right; */
    opacity: 0.1;
    filter: opacity(10%);
    /* display: none; */
  }

  .outline-custom {
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='16' ry='16' stroke='rgba(0,0,0,0.2)' strokeWidth='2' stroke-dasharray='6%2c 10' stroke-dashoffset='0' strokeLinecap='square'/%3e%3c/svg%3e");
    border-radius: 16px;
  }

  .settings-sidebar-links {
    @apply px-3 py-3 text-[#99999C] flex;
    @apply hover:bg-gray-100;
  }

  .settings-sidebar-links.active {
    @apply px-3 py-3 bg-[#FAFAFF] border-r-8 border-[#5540EB];
  }

  .hide-first-border > *:first-child {
    @apply border-none;
  }

  .label-border {
    @apply w-max px-1 py-0.5 text-xs border-[1px] border-[#E2E3E5] rounded-md;
  }

  .slide-tab-toggle {
    @apply w-full flex justify-between;
  }

  .slide-tab-toggle > div {
    @apply w-1/2 block rounded-md cursor-pointer;
  }

  .slide-tab-toggle > div > div {
    @apply w-full h-full py-1.5 text-center text-sm font-medium text-shiga-gray rounded-full;
  }

  .slide-tab-toggle > div > div.active {
    @apply text-black bg-white;
  }
}

.scrollbar-hide::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}

.scrollbar-hide {
  -ms-overflow-style: none; /* For IE and Edge */
  scrollbar-width: none; /* For Firefox */
}
